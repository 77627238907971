@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    scroll-behavior: smooth;
    overflow-x:hidden ;
  }
}
.px-100 {
  padding-left: 100px;
  padding-right: 100px;
}
.bg-logo {
  background: linear-gradient(128deg, #000 17.11%, transparent 98.58%);
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-logo-learn-More {
  background: linear-gradient(128deg, #000 17.11%, transparent 98.58%);
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-logo-services {
  background: linear-gradient(128deg, #000 17.11%, transparent 98.58%), url("./assets/images/bgServices.png");
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* unvisited link */
.fourthCard-bg {
  opacity: 1.5;
  background: url("./assets/images/secondBG.png"), black 0px -314.414px / 100% 139.882% no-repeat;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* -webkit-mask-image: linear-gradient(to right, transparent 0%, black 10%, black 90%, transparent 100%); */
}

.card {
  border-radius: 25px;
  background: linear-gradient(247deg, #181818 28.66%, rgba(246, 149, 37, 0.22) 354.49%);
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.40);
}




.image-container {
  position: relative;
  display: inline-block;
}

.image-container::before,
.image-container::after {
  content: "";
  position: absolute;
  background-color:white;
  /* Color of the lines */
}

/* Horizontal line */
.image-container::before {
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
}

/* Vertical line */
.image-container::after {
  top: 0;
  bottom: 0;
  left: 60%;
  width: 2px;
}

/* Additional styling for the image */
.image-container img {
  display: block;
  max-width: 100%;
  height: auto;
}
.customerCard {
  position: relative;
  width: 30%;
  height: 300px;
  padding: 1em 1.5em;
  margin: 2em auto;
  color: #000;
  background: #fff;
}
.customerCard:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-width: 70px 70px  0 0;
  border-style: solid;
  border-color: #f4f4f5;
  
}

.map {
  height: 450px;
  filter: grayscale(100%) invert(92%) contrast(83%);
}

.scroll-bar-hidden::-webkit-scrollbar {
  display: none;
}

.sep
{
  width: 1px;   height: 100px; background-color: #000;
}
.button-link {
  display: inline-block;
  padding: 1.3rem 1.5rem; /* Adjust padding as needed */
  background-color: #F69525;
  border-radius: 9999px; /* Adjust border-radius to round the button */
  /* Add other styles as needed */
}

.z-img-width{
  max-width: 2rem;
}
.z-img-height{
  height: 2rem;
}
.image-container-ft {
  width: 180px;
  height: 65px;
}

.image-ft {
  width: 100%; /* Fills container while maintaining aspect ratio */
}